'use strict';

/**
 * @description Initializes the necessary interactions for Google Analytics tracking
 */

var processInclude = require('base/util');

$(document).ready(function() {
    processInclude(require('int_gtm/googleAnalytics/googleAnalytics'));
    processInclude(require('./googleAnalytics/clientSideEvents'));
});
