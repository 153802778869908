'use strict';

const dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];
const HOME_COMPONENT = '.promo-block';
const NEWSLETTER_FORM = '.newsletter-optin-form';
const FILTER_BUTTON = '.refinement-container button:not(.sort)';
const PRICE_SLIDER = '.price-range';
const CATEGORY_REFINEMENT = '.category-refine';
const RECOMMENDATIONS_CAROUSEL = '.product-detail .recommendations .product-tile-container';
const RECOMMENDATIONS_CAROUSEL_TITLE = '.recommendations .slot-title';
const RECOMMENDATIONS_CAROUSEL_ID = '#product-carousel';

function buildEventBannerClick(promo) {
    return {
        event: 'promotionClick',
        ecommerce: {
            promoClick: {
                promotions: promo
            }
        }
    };
}

function buildEventBannerView(promos) {
    return {
        event: 'promoView',
        ecommerce: {
            promoView: {
                promotions: promos
            }
        }
    };
}

function buildEventNewsletterRegistration() {
    return {
        event: 'customEvent',
        eventCategory: 'Newsletter Registration',
        eventAction: 'Success',
        eventLabel: 'OK'
    };
}

function buildEventApplyRefinements(filterType, filterValue) {
    return {
        event: 'filter',
        eventCategory: 'Search',
        eventAction: filterType,
        eventLabel: filterValue
    };
}

function buildEventRecommendationsCarouselImpressions(currency, products) {
    return {
        event: 'carouselImpression',
        ecommerce: {
            currencyCode: currency,
            impressions: products
        }
    };
}

function buildSizeChartEvent(sku) {
    return {
        event: 'addToCart',
        eventCategory: 'Ecommerce',
        eventAction: 'Size Guide Open',
        eventLabel: sku
    };
}

module.exports = {
    bannerClick: function() {
        $(document).on('mousedown', HOME_COMPONENT, function() {
            var promo = {};

            promo.id = $(this).attr('data-id');
            promo.name = $(this).attr('data-name');
            promo.creative = $(this).attr('data-creative');

            /** Indicate a position other than 0 only if the component is a carousel  */
            if($(this).parents('.carousel').length > 0) {
                promo.position = $(this).parents('.carousel-item.active').index() + 1;
            } else {
                promo.position = 1;
            }

            dataLayer.push(buildEventBannerClick(promo));
        });
    },

    bannerPromo: function() {
        if($(HOME_COMPONENT).length > 0) {
            var promos = [];

            $(HOME_COMPONENT).each(function() {
                var promo = {};
                promo.id = $(this).attr('data-id');
                promo.name = $(this).attr('data-name');
                promo.creative = $(this).attr('data-creative');

                /** Indicate a position other than 0 only if the component is a carousel  */
                if($(this).parents('.carousel').length > 0) {
                    promo.position = $(this).parents('.carousel-item').index() + 1;
                } else {
                    promo.position = 0;
                }
                promos.push(promo);
            });

            dataLayer.push(buildEventBannerView(promos));
        }
    },

    newsletterRegistration: function() {
        $(document).on('submit', NEWSLETTER_FORM, function() {
            dataLayer.push(buildEventNewsletterRegistration());
        });
    },

    applyRefinements: function() {
        $('body').on('mousedown', FILTER_BUTTON, function() {
            var filterType = $(this).parents('.refinement-container').attr('id');

            var filterOption = $(this).find('.value').text().trim();
            var lastIndex = filterOption.lastIndexOf(' ');
            var filterValue = filterOption.substring(0, lastIndex);

            dataLayer.push(buildEventApplyRefinements(filterType, filterValue));
        });

        for(var i = 0; i < $(PRICE_SLIDER).length; i++) {
            $(PRICE_SLIDER)[i].noUiSlider.on('change', function() {
                var filterType = 'Price';

                var filterOptionStart = $(PRICE_SLIDER).attr('data-current-start');
                var filterOptionEnd = $(PRICE_SLIDER).attr('data-current-end');

                var filterValue = filterOptionStart + '-' + filterOptionEnd;

                dataLayer.push(buildEventApplyRefinements(filterType, filterValue));
            });
        }

        $('body').on('mousedown', CATEGORY_REFINEMENT, function() {
            var filterType = 'Category';

            var filterValue = $(this).find('.value').text();

            dataLayer.push(buildEventApplyRefinements(filterType, filterValue));
        });
    },

    sizeChart: function() {
        $('.size-chart .link').on('click', function() {
            var sku = $('.product-id').html();
            dataLayer.push(buildSizeChartEvent(sku));
        });
    }
};
