'use strict';

module.exports = {

    /**
     * When the GTM events are pushed with the value 'beacon' in the field 'transport',
     * the dataLayer library will attempt to use the Beacon API,
     * so events can be tracked despite of the user navigating away form the page;
     *
     * However, it is not supported by all browsers that are still in use:
     * https://caniuse.com/#feat=beacon
     *
     * This function polyfills the sendBeacon method of the window's navigator
     * with a synchrnouse XMLHttpRequest, to guarantee correct tracking
     *
     * Source: Polyfill Service (https://github.com/Financial-Times/polyfill-service)
     */
    polyfillBeaconAPI: function() {
        window.navigator.sendBeacon = window.navigator.sendBeacon || function(url, data) {
            var xhr = ('XMLHttpRequest' in window) ? new XMLHttpRequest() : new window.ActiveXObject('Microsoft.XMLHTTP');

            xhr.open('POST', url, false);
            xhr.setRequestHeader('Accept', '*/*');

            if (typeof data === 'string') {
                xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
                xhr.responseType = 'text/plain';
            } else if (Object.prototype.toString.call(data) === '[object Blob]') {
                if (data.type) {
                    xhr.setRequestHeader('Content-Type', data.type);
                }
            }

            xhr.send(data);

            return true;
        };
    },

    /**
     * Calling history.pushState() or history.replaceState() won't trigger a popstate event.
     *
     * This function is intended to create an alternative event, which can be used
     * to handle manual changes to the history stack.
     *
     * https://developer.mozilla.org/en-US/docs/Web/Events/popstate
     */
    installNewHistoryPushStateWindowEvent: function() {
        var pushState = window.history.pushState;

        window.history.pushState = function() {
            pushState.apply(this, arguments);

            $(window).trigger('pushstate');
        };
    },

    /**
     * Checks the results of all AJAX requests for JSON,
     * containing analytics events, and pushes them to the data layer, if any
     */
    pushAjaxEvents: function() {
        var dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];

        // Don't attach the listener if GTM or GA are not enabled
        if (!dataLayer) {
            return;
        }

        $(document).on('ajaxComplete', function(e, xhr) {
            var events;

            try {
                // Handle JSON repsponses
                var response = JSON.parse(xhr.responseText);

                events = response[window.googleAnalytics.ANALYTICS_EVENTS_KEY];
            } catch(e) {
                if (xhr.getResponseHeader('content-type') !== null) {
                    if (xhr.getResponseHeader('content-type').search('text/html') >= 0) {
                        // Handle HTML responses using the GTM events decorator template
                        events = [];

                        $(xhr.responseText).filter('[name="analytics-events"]').each(function() {
                            events = events.concat(JSON.parse($(this).val()));
                        });
                    }
                }
            }

            if (events && events.length) {
                for (var i = 0; i < events.length; i++) {
                    dataLayer.push(events[i]);
                }
            }
        });
    },

    /**
     * Checks the page for partial remote include templates,
     * containing analytics events, and pushes them to the data layer, if any
     */
    pushRemoteIncludeEvents: function() {
        var events = [];
        var dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];

        // Don't query the DOM if GTM or GA are not enabled
        if (!dataLayer) {
            return;
        }

        $('[name="analytics-events"]').each(function() {
            events = events.concat(JSON.parse($(this).val()));
        });

        if (events && events.length) {
            for (var i = 0; i < events.length; i++) {
                dataLayer.push(events[i]);
            }
        }
    }
};
